<template>
  <b-card body-class="p-1rem m-0" class="mb-0 w-100 h-100" :key="update_collapses">
    <div v-if="Object.keys(video_selected).length === 0">

      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <h5 class="m-0 mr-1">{{ $t('ia.video.optionVideo') }}</h5>
          <b-button variant="flat-secondary" class="info-button-radious" @click="sidebar_info = true">
            <feather-icon icon="InfoIcon" class="cursor-pointer icon-info"></feather-icon>
          </b-button>
        </div>
  
        <b-link @click="startOverAction()" v-if="script.presenter_uuid !== null">{{ $t('ia.video.startOver') }}</b-link>
      </div>
      <div :class="`backgroud-options mt-1 position-relative`">
        <div 
          class="d-flex justify-content-between align-items-center position-relative cursor-pointer"
          @click="clickCollapse(`icon_plus_0`, 0, 'presenter')"
        >
          <strong>{{ $t('ia.video.portrait') }}</strong>
          <div class="container-plus-less-icon" :ref="`icon_plus_0`">
            <span></span>
            <span></span>
          </div>
        </div>
        <b-collapse v-model="collapses.presenter" accordion="options-accordion" role="tab">
          <div class="mt-1 d-flex flex-wrap">
            <div 
              class="d-flex flex-column align-items-center justify-content-center avenir-medium add-presenter-button"
              @click="openFilePresenter"
            >
              <feather-icon icon="PlusIcon"></feather-icon>
              {{ $t('lists.add') }}
            </div>
            <div v-for="(presenter, index) in presenters" :key="presenter.uuid" class="position-relative">
              <b-img 
                :src="presenter.url" 
                :ref="`img-presenter-${index}`" 
                class="img-presenter" 
                @click="selectPresenter(presenter.uuid, index)"
              ></b-img>
              <div class="container-trash-presenter" v-if="!presenter.is_public" @click="deletePresenter(presenter.uuid)">
                <feather-icon icon="TrashIcon" class=""></feather-icon>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div :class="`backgroud-options mt-1 position-relative cursor-pointer`">
        <div class="d-flex justify-content-between align-items-center position-relative" @click="clickCollapse(`icon_plus_1`, 1, 'script')">
          <strong>{{ $t('ia.video.script') }}</strong>
          <div class="container-plus-less-icon" :ref="`icon_plus_1`">
            <span></span>
            <span></span>
          </div>
        </div>
        <b-collapse v-model="collapses.script" accordion="options-accordion" role="tab">
          <b-button-group class="w-100 mt-1">
            <b-button 
              @click="tabs_options = 0" 
              :class="{'active': tabs_options === 0}" 
              class="button-tabs-options-ia mr-1" 
              variant="button-tabs-options-ia"
            >
            {{ $t('ia.video.typeScript') }}
            </b-button>
            <b-button 
              @click="tabs_options = 1" 
              :class="{'active': tabs_options === 1}" 
              class="button-tabs-options-ia" 
              variant="button-tabs-options-ia"
            >{{ $t('ia.video.uploadVoice') }}</b-button>
          </b-button-group>
  
          <div v-if="tabs_options === 0" class="mt-1">
            <b-form-group label="Script" label-for="textarea-formatter">
              <b-form-textarea
                id="textarea-formatter"
                placeholder="Script"
                v-model="script.text_script"
                :state="state_script"
                @input="changeText"
              ></b-form-textarea>
              <b-form-invalid-feedback id="invalid-feedback-description">
                <span v-if="script.text_script && script.text_script.length > 3000">{{ $t('creator.3000characters') }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
            
            <div class="d-flex align-items-center justify-content-between">
              <b-form-group label="Language" label-for="textarea-formatter" class="w-80">
                <component :is="vue_select" :options="languages_options" label="title" v-model="script.language" class="select-script">
                </component>
              </b-form-group>
              <div>
                <audio :src="audio_src" id="audio_script"></audio>
                <b-button pill variant="outline-dark" @click="playAudio" :disabled="requesting_audio || script.text_script === '' || script.text_script === null">
                  <b-img class="icon-svg" :src="require('@/assets/images/svg/wave-sound.svg')"/>
                </b-button>
              </div>
            </div>
  
            <b-form-group label="Speaker" label-for="textarea-formatter" v-if="script.language">
              <component :is="vue_select" :options="speakers_options" label="title" v-model="script.speaker" class="select-script"></component>
            </b-form-group>
  
            <b-form-group label="Style" label-for="textarea-formatter" v-if="styles.length > 0">
              <component :is="vue_select" :options="styles" v-model="script.style" class="select-script"></component>
            </b-form-group>
          </div>
          <div v-else-if="tabs_options === 1">
            <div class="main-container-preview-file mt-1">
              <div class="container-custom-input-file">
                <div class="container-file">
                  <p class="mb-0">{{ $t('ia.video.uploadRecording') }}</p>
                </div>
                <b-form-file 
                  accept=".png"
                  class="custom-input" 
                  disabled
                >
                </b-form-file>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <b-form-file 
        class="d-none" 
        ref="input-file-presenter" 
        accept=".jpg, .png" 
        v-model="image_presenter"
        @change="changePresenter"
      ></b-form-file>
      <b-sidebar shadow backdrop right v-model="sidebar_info" @change="hasClosedSidebar">
          <template #header>
            <div class="p-1 border-bottom d-flex">
              <h4>Learn More: Audio to Video Options </h4>
  
              <feather-icon icon="XIcon" class="x-icon-sidebar-info" @click="sidebar_info = false"></feather-icon>
            </div>
          </template>
          <div class="p-1">
            <h2>Portrait</h2>
            <p>Choose from one of the default selections we’ve provided, or add your own. If adding a portrait, there are a few guidelines to follow in order to get an optimized result:</p>
  
            <ul class="pl-1">
                <li>Choose an image of a person facing front, from a medium distance (including some of their torso).</li>
                <li>The minimum size of the head within the image should be <code>200x200 pixels</code>.</li>
                <li>Neutral facial expression with the mouth closed.</li>
                <li>Good, solid lighting.</li>
                <li>Nothing in front of the face (hands, hair, etc).</li>
                <li>File size maximum of <code>10Mb</code>.</li>
            </ul>
  
            <p>After selecting or adding a portrait, you can adjust the aspect ratio of the final result.</p>
  
            <h2>Script</h2>
            <p>You have two options when providing the script for your portrait to speak: Typing your script that will be converted to speech, or uploading your own audio.</p>
  
            <p>If you choose to type your script, enter it into the input field (maximum of <code>3000 characters</code>). Then choose a language, voice, and style of speaking. Use the button with the speaker icon to hear a preview.</p>
  
            <p>Choosing a language does not translate the text you’ve entered into the specified language, it will only pronounce what you’ve written in the correct accent. Also, not all voices are available with every language or speaking style.</p>
  
            <p>If you choose to upload audio, drag it into the dropzone, or simply click the dropzone to open a file browser.</p>
          </div>
      </b-sidebar>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-between border-bottom pb-2">
        <div class="d-flex justify-content-center flex-column">
          <h5 class="m-0 mr-1">Video Properties </h5>
          <p class="m-0 avenir-medium">{{ formatDateIA(new Date(video_selected.created_at)) }}</p>
        </div>
        <b-img :src="video_selected.presenter.url" class="avatar-presenter"></b-img>
      </div>
      <div class="pt-2">
        <div class="row mb-1">
          <div class="col-12 mb-1 overflow-long-text">
            <span class="d-block avenir-medium">SCRIPT</span>
            <span class="d-block">{{ video_selected.text_script }}</span>
          </div>
          <div class="col-6 mb-1">
            <span class="d-block avenir-medium">LANGUAGE</span>
            <span class="d-block">{{ video_selected.language }}</span>
          </div>
          <div class="col-6 mb-1">
            <span class="d-block avenir-medium">VOICE</span>
            <span class="d-block"> {{ video_selected.voice_name }}</span>
          </div>
          <div class="col-6 mb-1">
            <span class="d-block avenir-medium">STYLE</span>
            <span class="d-block"> {{ video_selected.style ? video_selected.style : '--' }}</span>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>

import { 
  BCard,
  BCollapse,
  VBToggle,
  BFormFile,
  BImg,
  BButton,
  BButtonGroup,
  BFormTextarea,
  BFormGroup,
  BSidebar,
  BLink,
  BFormInvalidFeedback,
} from 'bootstrap-vue';
import { addPresenter, getVoices, deletePresenter } from '@/services/ia'
import { formatDateIA } from '@/libs/utils/times';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import config from '@/services/config'

export default {
  name: 'siderVideoActions',
  directives: {
    'b-toggle': VBToggle
  },
  components: {
    BCard,
    BCollapse,
    BFormFile,
    BImg,
    BButton,
    BButtonGroup,
    BFormTextarea,
    BFormGroup,
    BSidebar,
    BLink,
    BFormInvalidFeedback,
  },
  props: {
    script: {
      type: Object,
    },
    video_selected: {
      type: Object,
    },
    presenters: {
      type: Array,
    }
  },
  computed: {
    state_script() {
      if (this.script.text_script) return this.script.text_script.length <= 3000
      else return false
    },
  },
  data() {
    return {
      formatDateIA,
      collapses: {
        presenter: true,
        script: false,
      },
      vue_select: null,
      image_presenter: null,
      tabs_options: 0,
      sidebar_info: false,
      voices: [],
      languages_options: [],
      speakers_options: [],
      styles: [],
      script_reference: {
        language: null,
        speaker: null,
      },
      update_collapses: false,
      change_script: false,
      audio_src: '',
      requesting_audio: false
    }
  },
  created() {
    this.getAllVoices();
  },
  async mounted() {
    // ANIMATION LESS ICON 
    if (this.$refs['icon_plus_0']) {
      const container_icon = this.$refs['icon_plus_0'];
      container_icon.classList.toggle("transition-icon-plus");
    }
    // -------------------
    this.vue_select = (await import('vue-select')).default;
    this.axios = (await import('axios')).default
  },
  methods: {
    changeText() {
      this.change_script = true
    },
    playAudio() {
      if (this.script.text_script) {
        if (this.change_script) {
          const data = this.script.speaker.rest
          data['text'] = this.script.text_script
          this.requesting_audio = true
          this.axios({
            method: 'POST',
            url: `${config.urlCore}/api/v3/ai/get-audio/`,
            responseType: 'arraybuffer',
            data,
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
          })
            .then(response => {
              const blob = new Blob([response.data], { type: 'audio/mpeg' });

              const blob_audio = URL.createObjectURL(blob);

              this.audio_src = blob_audio

              setTimeout(() => {
                const btn_audio = document.getElementById('audio_script');
                btn_audio.play()
              }, 100);
              this.change_script = false
              this.requesting_audio = false
            })
        } else {
          const btn_audio = document.getElementById('audio_script');
          btn_audio.play()
        }
      }
    },
    deletePresenter(presenter_uuid) {
      deletePresenter(presenter_uuid).then(() => {
        this.script.presenter_uuid = null;
        this.$emit('delete_presenter', presenter_uuid)
      });
    },
    selectPresenter(presenter_uuid, index) {
      this.clearAllPresenterSelected();
      this.$refs[`img-presenter-${index}`][0].classList.add(('active-image'));
      this.script.presenter_uuid = presenter_uuid;
      this.$emit('clean_video_selected');
      setTimeout(() => {
        this.collapses.presenter = false;
        this.clickCollapse(`icon_plus_1`, 1, 'script');
        
      }, 200);
    },
    clearAllPresenterSelected() {
      for (let index = 0; index < this.presenters.length; index++) {
        this.$refs[`img-presenter-${index}`][0].classList.remove(('active-image'))
      }
    },

    getAllVoices() {
      getVoices().then((response) => {
        this.voices = response
        this.formatLocaleVoices(response);
      })
    },
    formatLocaleVoices(voices) {
      voices.forEach(voice => {
        if (!this.languages_options.find(i => i.title === voice.language)) {
          this.languages_options.push({id: `${voice.id}-${voice.language}`, title: voice.language, language: voice.locale});
        }
      });
      this.script.language = this.languages_options.find((i) => i.language === 'es-MX')
    },
    clickCollapse(icon_plus, index, collapse) {
      this.collapses[collapse] = !this.collapses[collapse]
      this.clearActive(index)
      const container_icon = this.$refs[icon_plus];
      container_icon.classList.toggle("transition-icon-plus");
    },
    clearActive(index) {
      for (let i = 0; i < 2; i++)  {
        if (i !== index) this.$refs[`icon_plus_${i}`].classList.remove('transition-icon-plus')
      }
    },
    openFilePresenter() {
      this.$refs['input-file-presenter'].$refs['input'].click()
    },
    addPresenter() {
      const form_data = new FormData();
      form_data.append('image', this.image_presenter);
      addPresenter(form_data).then((response) => {
        if (response.status >= 400) {
          loadToastificationContent().then((module) => {
            this.$toast({
              component: module.default,
              props: {
                title: 'Ups hubo un error',
                icon: "AlertTriangleIcon",
                text: response.data.response.message.image[0],
                variant: 'danger'
              },
            });
          })
        } else {
          this.presenters.unshift(response);
        }
      })
    },
    changePresenter() {
      setTimeout(() => {
        if (this.image_presenter) {
          this.addPresenter();
        }
      }, 200);
    },
    hasClosedSidebar(value) {
      if (!value) this.sidebar_info = false
    },
    clearCollapses() {
      this.collapses.presenter = false;
      this.collapses.script = false;
    },
    startOverAction() {
      this.$emit('clear_script'); 
      this.script.language = this.languages_options.find((i) => i.language === 'es-MX')
      this.update_collapses = !this.update_collapses;
      this.clearCollapses()
    }
  },
  watch: {
    speaker(val) {
      if (val.styles && val.styles.length > 0) this.styles = val.styles;
    },
    script: {
      handler(value) {
        setTimeout(() => {
          const language = value.language;
          const speaker = value.speaker;
          if (speaker !== this.script_reference.speaker) this.change_script = true

          if (language !== this.script_reference.language) {
            this.change_script = true
            this.speakers_options = [];
            this.styles = []
            this.script.style = null;
  
            const speakers = this.voices.filter((v) => v.locale === language.language)
            speakers.forEach((s) => {
              this.speakers_options.push({id: s.id, title: s.name, styles: s.styles, rest: s})
            });
            this.script.speaker = this.speakers_options[0];
            
            this.script_reference.language = language;
          } else if (value.speaker && value.speaker.rest && value.speaker.rest.styles && value.speaker.rest.styles.length > 0) {
            this.styles = value.speaker.rest.styles;
          }
        }, 20);
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
button.disabled {
  cursor: not-allowed;
}
.w-80 {
  width: 80% !important;
}
.icon-svg {
  width: 20px;
}

.avatar-presenter {
  width: 42px;
  height: 42px;
  border-radius: 0.4em;
  border: 1px solid #dadada;
  object-fit: cover;
}
.overflow-long-text {
  max-height: 400px;
  overflow-y: scroll;
}
.container-trash-presenter {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  flex-shrink: 0;
  border: 1px solid gray;
  position: absolute;
  bottom: 0.5em;
  right: 0;
  cursor: pointer;
}
.info-button-radious {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  padding: 0;
  .icon-info {
    width: 18px;
    height: 18px;
  }
}
.backgroud-options {
  background-color: #F8F9FA;
  padding: 1em;
  border-radius: 0.5em;

  .add-presenter-button {
    border: 2px dashed #b7b9bb;
    border-radius: 100%;
    width: 82px;
    cursor: pointer;
    height: 82px;
    flex-shrink: 0;
  }
  .img-presenter {
    width: 82px;
    height: 82px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 100%;
    margin-left: 0.5em;
    margin-bottom: 1em;
    border: 0.2px solid rgba(128, 128, 128, 0.466);
  }
  .active-image {
    box-shadow: 0 0 0 2px white, 0 0 0 5px purple;
  }
}
.container-plus-less-icon {
    background: transparent;
    width: 30px;
    height: 30px;
    border: 0;
    position: relative;

    span {
      position: absolute;
      transition: 300ms;
      background: #999999;

      &:first-child {
        top: 25%;
        bottom: 25%;
        width: 10%;
        left: 45%;
      }
      &:last-child {
        left: 25%;
        right: 25%;
        height: 10%;
        top: 45%;
      }
    }
  }
  .transition-icon-plus span {
    transform: rotate(90deg);
  }
  .transition-icon-plus span:last-child {
    left: 50%;
    right: 50%;
  }
  .button-tabs-options-ia {
    background: #e9ecef !important;
    border-radius: 0.7em !important;
    padding: 1.5em !important;

    &:hover  {
      background: #dee2e6 !important;

      &:disabled {
        background: #e9ecef !important;
      }
    }

    &.active {
      background-color: #55a6c4 !important;
      color: white !important
    }
  }
.main-container-preview-file {
  
  .container-file {
    // top: 40px;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(0,0,0,.45);
    height: 50px;
    font-size: 12px;
    cursor: pointer;
    .img-camera {
      width: 20px;
      display: block;
    }
  }
  .container-custom-input-file {
    position: relative;
    height: 50px;
  }
  .container-blob {
    height: 120px;
    background-color: #DEE2E6;
    border-radius: 0.7em;
    position: relative;
   
    .container-trash-blob {
      position: absolute;
      top: 0.5em;
      right: 0.5em;     
      cursor: pointer;
      padding: 0.6em;
      border-radius: 10em;
      
      &:hover {
        background-color: #bdc0c4da;
      }
      .icon-trash-blob {
        color: black;
      }
    }
    .img-blob {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
<style lang="scss">
.custom-input {
  .custom-file-label {
    border: 1px dashed #3483fa !important;
    cursor: pointer;
    color: transparent;
    position: absolute;
    height: 50px !important;
    top: 0px;
    background: transparent !important;

    &:hover {
      border: 2px solid #3483fa !important;
      cursor: pointer;
    }

    &::after {
      display: none;
    }
  }
}
.select-script .vs__clear {
  display: none;
}
</style>
